import React, { createContext, useState, useContext, useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const DocContext = createContext(null);

export const DocContextProvider = ({ children }) => {
  const [ref, setRef] = useState(null);
  const pdfRef = useRef();

  const downloadPDF = async () => {
    const input = pdfRef.current;
    const pdf = new jsPDF("p", "mm", "a4");

    const margin = 5;
    const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
    const pdfHeight = pdf.internal.pageSize.getHeight() - 2 * margin;

    const canvas = await html2canvas(input, {
      scale: 2,
      useCORS: true,
    });

    const imgData = canvas.toDataURL("image/png");
    const imgWidth = pdfWidth;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    let heightLeft = imgHeight;
    let yPosition = margin;

    pdf.addImage(imgData, "PNG", margin, yPosition, imgWidth, imgHeight);
    heightLeft -= pdfHeight;

    while (heightLeft > 0) {
      pdf.addPage();
      yPosition = margin;
      pdf.addImage(imgData, "PNG", margin, yPosition, imgWidth, imgHeight);
      heightLeft -= pdfHeight;
    }

    pdf.save("document.pdf");
  };

  return (
    <DocContext.Provider value={{ pdfRef, ref, setRef, downloadPDF }}>
      {children}
    </DocContext.Provider>
  );
};

export const useDocContext = () => {
  const context = useContext(DocContext);
  if (!context) {
    throw new Error("useDocContext must be used within a DocContextProvider");
  }
  return context;
};

export default DocContextProvider;
